let menuData = [
    {
        "title": "用户管理",
        "key":"user_manage",
        "children": [
            {
                "title": "名单管理",
                "url": "/a/name_list"
            },
            {
                "title": "导入学生",
                "url": "/a/students",
            },
            {
                "title": "重置密码",
                "url": "/t/password_reset"
            },]
    },


    {
        "title":"意见建议",
        "url":"https://support.qq.com/product/378305"
    }

];
if(!window.config.is_open_paike){
    menuData=menuData.filter(menu=>menu.title!=='排课管理')
}
module.exports = menuData;
