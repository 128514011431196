let menuData = [

    {
        "title":"意见建议",
        "url":"https://support.qq.com/product/378305"
    }
    ];

if(!window.config.is_open_paike){
    menuData=menuData.filter(menu=>menu.title!=='课程')
}
if(!window.config.is_open_virtual_room){
    menuData=menuData.filter(menu=>menu.title!=='远程实验')
}

module.exports = menuData;
